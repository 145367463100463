<template>
    <div class="header_space">
        <p class="main_font">
            {{ userName }}
        </p>
        <div style="height:10vh" />
        <img
            src="../assets/logos/logo-dark4.svg"
            alt="hypeIFC"
            class="vcenter_image"
        />
        <div @mouseover="piHover = true" @mouseleave="piHover = false">
            <footer class="main_font_lightgray">
                <small v-show="!piHover">π</small>
                <small v-show="piHover">{{ pi }}</small>
            </footer>
        </div>
    </div>
</template>

<script>
import { version, name } from '../../package.json';

const log = (message) => console.log('Home:', message);

function buildPi() {
    log('buildPi');
    const stage = process.env.VUE_APP_STAGE;
    const pi = `${name} ${stage} v${version}`;
    return pi;
}

function setUserName(userName) {
    log(`setUserName ${userName}`);
    this.userName = userName;
}

function setHeader() {
    this.$root.$emit('set_header_title', this.header);
}

export default {
    data() {
        return {
            piHover: false,
            pi: buildPi(),
            userName: '',
            header: {
                title: 'HypeIFC',
            },
        };
    },

    mounted() {
        log('home');
        this.setHeader();

        console.log(process.env.VUE_APP_BUILT);

        this.$root.$on('user_signin', this.setUserName);
    },

    methods: {
        setUserName,
        setHeader,
    },
};
</script>

<style scoped>
.vcenter_image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 70vh;
    max-width: 70vw;
}

footer {
    position: fixed;
    bottom: 10px;
    right: 10px;
}
</style>

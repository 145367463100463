<template>
    <div id="app">
        <div id="header">
            <table width="100%">
                <tr>
                    <td align="left">
                        <a href="index.html">
                            <img
                                width="64"
                                height="64"
                                src="./assets/logos/logo-dark4-notext.svg"
                            />
                        </a>
                    </td>
                    <td align="center">
                        <div v-if="isRender()">
                            <table width="50%">
                                <tr>
                                    <td>
                                        <button
                                            class="main_button main_font"
                                            @click="resetModel()"
                                        >
                                            Reset
                                        </button>
                                    </td>
                                    <td
                                        v-for="(item, index) in header.items"
                                        :key="item.key"
                                        align="center"
                                        class="selector_default"
                                        :class="
                                            isItemSelected(index)
                                                ? 'selector_active'
                                                : 'selector_hover'
                                        "
                                        style=""
                                        @click="selectItem(index)"
                                    >
                                        <div>
                                            <i
                                                class="material-icons selector_icon"
                                                >{{ item.icon }}</i
                                            >
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div v-else-if="isFiles()">
                            <table width="100%">
                                <tr>
                                    <td align="right">
                                        <div class="header_index main_font">
                                            <b>{{ header.title }}</b>
                                        </div>
                                    </td>
                                    <td align="center">
                                        <button
                                            class="main_button main_font"
                                            @click="initiateUpload()"
                                        >
                                            Upload...
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div v-else-if="isIndex()">
                            <div class="header_index main_font">
                                <b>{{ header.title }}</b>
                            </div>
                        </div>
                        <div v-else>
                            <div class="header_default main_font">
                                <b>{{ header.title }}</b>
                            </div>
                        </div>
                    </td>
                    <td align="right">
                        <router-link to="/navigation">
                            <img
                                width="64"
                                height="64"
                                src="./assets/burger-menu.svg"
                            />
                        </router-link>
                    </td>
                </tr>
            </table>
        </div>

        <router-view />
        <!--HelloWorld msg="Welcome to Your Vue.js App"/-->
    </div>
</template>

<script>
const log = (message) => console.log('App:', message);

function readCookie(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

function setCookie({ name, value, expiresInSeconds }) {
    const d = new Date();
    d.setTime(d.getTime() + expiresInSeconds * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/; SameSite=None; Secure`;
}

function decodeJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

function parseQueryString(queryString) {
    const result = {};

    const sanitizedQueryString =
        queryString.charAt(0) === '/' ? queryString.substring(1) : queryString;

    const entries = sanitizedQueryString.split('&');

    entries.forEach((entry) => {
        const [key, value] = entry.split('=');
        result[key] = value;
    });

    return result;
}

function processSignIn() {
    log('processSignIn');

    const queryStringInUrlFragment = window.location.hash.substring(1);
    const queryStringAsObject = parseQueryString(queryStringInUrlFragment);

    if (!queryStringAsObject.token_type) {
        log('not a sign in!');
        return;
    }
    const authorizationValue = `${queryStringAsObject.token_type} ${queryStringAsObject.id_token}`;
    const decodedJwt = decodeJwt(queryStringAsObject.id_token);

    log('sign in performed, information received:');
    log(queryStringAsObject);
    log(authorizationValue);
    log(decodedJwt);

    setCookie({
        name: 'id_token',
        value: queryStringAsObject.id_token,
        expiresInSeconds: queryStringAsObject.expires_in,
    });
}

function updateSignInName() {
    log('updateSignInName');
    const idToken = readCookie('id_token');

    if (!idToken) {
        return;
    }

    const decodedJwt = decodeJwt(idToken);

    log('app afterMount, decoded token:');
    log(decodedJwt);

    this.userName = `Welcome ${decodedJwt.email}!`;

    this.$root.$emit('user_signin', this.userName);
}

function isRender() {
    log('isRender');
    return this.header.type === 'render';
}

function isFiles() {
    log('isFiles');
    return this.header.type === 'files';
}

function isIndex() {
    log('isIndex');
    return this.header.type === 'main';
}

function isItemSelected(index) {
    return this.header.selectedIndex === index;
}

function selectItem(index) {
    log(`selectItem ${index}`);
    this.header.selectedIndex = index;

    this.$root.$emit('render_select_item', index);
}

function resetModel() {
    log('resetModel');
    this.$root.$emit('render_reset_model');
}

function initiateUpload() {
    log('initiateUpload');

    this.$router.push({ name: 'Upload' });
}

function setHeaderTitle(header) {
    log('set_header_title');
    this.header = header;
}

export default {
    data() {
        return {
            header: {
                title: 'HypeIFC',
                type: 'main',
                selectedIndex: 0,
                items: [],
            },
            userName: 'you are not signed in',
            yo: true,
        };
    },

    beforeMount() {
        log('app');
        this.processSignIn();
    },

    mounted() {
        this.updateSignInName();

        this.$root.$on('set_header_title', this.setHeaderTitle);
    },

    methods: {
        initiateUpload,
        isFiles,
        isIndex,
        isItemSelected,
        isRender,
        processSignIn,
        resetModel,
        selectItem,
        setHeaderTitle,
        updateSignInName,
    },
};
</script>

<style>
:root {
    --main_color_bg_light: rgb(222, 222, 222);
    --main_color_bg_lighter: rgb(240, 240, 240);
    --main_color_bg_alternative: rgb(210, 160, 240);
    --main_color_fg_light: rgb(168, 72, 192);
    --main_color_fg_dark: rgb(56, 24, 64);

    --header_height: 80px;
}

html {
    overflow-y: scroll;
}

.main_font {
    font-family: monospace;
    color: var(--main_color_fg_dark);
}

.main_font_lightgray {
    font-family: monospace;
    color: lightgray;
}

.main_font_bold {
    font-family: monospace;
    font-weight: bold;
    color: var(--main_color_fg_dark);
}

.header_default {
    white-space: nowrap;
    font-size: min(max(30px, 4vw), 50px);
}

.header_index {
    white-space: nowrap;
    font-size: min(max(30px, 4vw), 50px);
}

.selector_default {
    /* background: var(--main_color_bg_light); */
    /* transition-duration: 0.4s; */
    padding: 5px;
    color: var(--main_color_fg_dark);
}

.selector_hover:hover,
.selector_active {
    /* background: var(--main_color_bg_light); */
    color: var(--main_color_fg_dark);
    outline: 2px solid var(--main_color_fg_dark);
}

.selector_icon {
    font-size: min(45px, 8vw);
    user-select: none;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}

.header_space {
    position: relative;
    top: var(--header_height);
    width: 100%;
}

a.navigation_link:link,
a.navigation_link:visited {
    color: var(--main_color_fg_dark);
    font-weight: bold;
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    font-size: 5vh;
}

a.navigation_link:hover,
a.navigation_link:active {
    color: var(--main_color_fg_light);
    font-weight: bold;
    white-space: nowrap;
    font-size: 5vh;
}

.loading_image {
    display: table-cell;
    height: 70vh;
    text-align: center;
    width: 100vw;
    vertical-align: middle;
}

#header {
    left: 0;
    top: 0;
    width: 100%;
    height: var(--header_height);
    position: fixed;
    background-color: var(--main_color_bg_lighter);
    z-index: 10000;
}

.main_button,
.files_button {
    border: none;
    padding: 14px;
    background-color: var(--main_color_fg_dark);
    color: white;
    font-size: min(4vw, 2vh);
    transition-duration: 0.4s;
}

.main_button:hover,
.files_button:hover {
    outline: 2px solid var(--main_color_fg_dark);
    color: var(--main_color_fg_dark);
    background-color: white;
}

.main_button:disabled,
.files_button:disabled {
    outline: 0px;
    color: lightgray;
    background-color: gray;
}

.files_button {
    width: 50%;
}

.files_button:hover {
}
</style>

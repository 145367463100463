import Vue from 'vue';
import VueRouter from 'vue-router';
import LiquorTree from 'liquor-tree';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

// make sure App and Home are immediately ready
import App from './App.vue';
import Home from './components/Home';

// lazy load other router components
const Render = () => import('./components/Render');
const Files = () => import('./components/Files');
const Navigation = () => import('./components/Navigation');
const Compliance = () => import('./components/Compliance');
const Semantics = () => import('./components/Semantics');
const Settings = () => import('./components/Settings');
const Upload = () => import('./components/Upload');

Vue.config.productionTip = false;

const routes = [
    {
        path: '/',
        component: Home,
    },
    {
        path: '/navigation',
        name: 'Navigation',
        component: Navigation,
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
    },
    {
        path: '/render',
        name: 'Render',
        component: Render,
        props: true,
    },
    {
        path: '/upload',
        name: 'Upload',
        component: Upload,
        props: true,
    },
    {
        path: '/compliance',
        name: 'Compliance',
        component: Compliance,
        props: true,
    },
    {
        path: '/semantics',
        name: 'Semantics',
        component: Semantics,
        props: true,
    },
    {
        path: '/files',
        name: 'Files',
        component: Files,
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
    },
    {
        path: '*',
        component: Home,
    },
];

const router = new VueRouter({
    routes,
});

Vue.use(VueRouter);

Vue.component(Splitpanes.name, Splitpanes);
Vue.component(Pane.name, Pane);
Vue.component(LiquorTree.name, LiquorTree);

new Vue({
    render: (h) => h(App),
    router,
}).$mount('#app');
